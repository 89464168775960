import { LocationDescriptorObject } from 'history';
import { identity, omitBy, pick, pickBy } from 'lodash';
import { inflate, deflate } from 'pako';
import qs, { IParseOptions } from 'qs';

import { t } from '@core/i18n';
import { Option } from '@shared/components/select-new/Select';
import ROUTES from '@shared/constants/routes';
import { PrimarySocietyGroup, Societies } from '@shared/constants/societies';
import { ConfigConstants } from '@shared/models/config';
import { IGraphItem } from '@shared/models/IGraphItem';
import { IUser, IUserSociety } from '@shared/models/IUser';
import { BuildingTypeGroups } from '@shared/types/common/building';
import { history } from '@shared/utils/history';

export const arrayToCsv = (arr?: any[], sort = false): string => {
  if (!Array.isArray(arr) || !arr.length) {
    return '';
  }

  // Pluck out the lengthy values from the array
  const items = arr.reduce<string[]>((result, item) => {
    if (typeof item === 'string' && item) {
      result.push(item);
    } else if (typeof item === 'number') {
      result.push(item.toString());
    }

    return result;
  }, []);

  // Return CSV string
  return sort ? items.sort().join(',') : items.join(',');
};

export const objectArrayToCsv = (arr?: Record<string, any>[], key = '', sort = false): string => {
  if (!Array.isArray(arr) || !arr.length) {
    return '';
  }
  if (!key || !Object.prototype.hasOwnProperty.call(arr[0], key)) {
    return '';
  }

  // Pluck out the values from the array of objects
  const items = arr.reduce<string[]>((result, item) => {
    if (Object.prototype.hasOwnProperty.call(item, key)) {
      if (typeof item[key] === 'string' && item[key]) {
        result.push(item[key]);
      } else if (typeof item[key] === 'number') {
        result.push(item[key].toString());
      }
    }

    return result;
  }, []);

  // Return CSV string
  return sort ? items.sort().join(',') : items.join(',');
};

export const isNullable = (value: any) => {
  return typeof value === 'undefined' || value === 'undefined' || value === null || value === 'null';
};

export const getDefaultRoute = (): string => {
  return `${ROUTES.marketplace.insights.root}${ROUTES.marketplace.insights.acquisitions}`;
};

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url?: string) {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.startsWith(url)) {
    return true;
  }

  return false;
}

export const getDefaultError = (error?: string, altText?: string) => {
  return error || altText || 'Something went wrong. Please contact us for assistance.';
};

export const getQueries = (): ParsedQuery => parseQueries(history.location.search);

export const getQueryValueString = (key: string, queries?: Query): string => {
  const query = queries || parseQueries(history.location.search);

  if (typeof query[key] === 'object' && query[key] && Array.isArray(query[key])) {
    return (query[key] as string[]).join(',');
  } else if (typeof query[key] === 'string' && query[key]) {
    return query[key] as string;
  }

  return '';
};

export const getQueryValueStringBoolean = (key: string, queries?: Query): string => {
  // For filters which accept string 'true' or 'false
  const query = queries || parseQueries(history.location.search);

  if (query[key] === 'true') {
    return '1';
  }

  if (query[key] === 'false') {
    return '0';
  }

  return '';
};

export const getQueryValueNumber = (key: string, queries?: Query): number => {
  const query = queries || parseQueries(history.location.search);
  const parsedValue = typeof query[key] === 'string' ? Number(query[key]) : 0;

  return !isNaN(parsedValue) ? parsedValue : 0;
};

// const initQueries = useMemo(() => getQueries(), []);

export const getQueryValueStringArray = (key: string, queries?: Query): string[] => {
  const query = queries || parseQueries(history.location.search);

  if (typeof query[key] === 'object' && query[key] && Array.isArray(query[key])) {
    return (query[key] as string[]).filter((item) => item);
  } else if (typeof query[key] === 'string' && query[key]) {
    return String(query[key])
      .split(',')
      .filter((item) => item);
  }

  return [];
};

export const getQueryValueNumberArray = (key: string, queries?: Query): number[] =>
  getQueryValueStringArray(key, queries).map((item) => parseInt(item));

export const parseQueries = (search?: QueryString, options?: IParseOptions): ParsedQuery => {
  if (!search) {
    return {};
  }

  return qs.parse(search, {
    arrayLimit: Infinity,
    ignoreQueryPrefix: true,
    interpretNumericEntities: true,
    ...options,
  }) as ParsedQuery;
};

export const pickQueries = <T = unknown>(queries: Query, keys: readonly any[]) => pick<Query>(queries, keys) as T;

export const getQueriesAsSearch = (queries?: any, encode = true): string =>
  qs.stringify({ ...(queries || getQueries()) }, { addQueryPrefix: true, encode: encode, skipNulls: true });

export const isQueryValueTruthy = (value: any) => {
  return !isNullable(value) && value !== false;
};

export const setQueries = (
  queries: any,
  replace?: boolean,
  locationArgs?: Partial<LocationDescriptorObject>,
  encode = false
) => {
  const args = {
    search: getQueriesAsSearch(
      omitBy(queries, (value) => !value),
      encode
    ),
    ...locationArgs,
  };

  if (replace) {
    return history.replace(args);
  }

  return history.push(args);
};

export const deleteQueries = (
  queriesToDelete?: string[],
  replace?: boolean,
  locationArgs?: Partial<LocationDescriptorObject>
) => {
  if (!queriesToDelete) {
    return setQueries({}, replace, locationArgs);
  }

  const currentQueries = getQueries();
  const finalQueries = omitBy(currentQueries, (value, key) => queriesToDelete.includes(key));

  setQueries(finalQueries, replace, locationArgs);
};

export interface GetQueriesCountData {
  blacklistKeys?: string[];
  groupKeys?: string[][];
  queries?: ParsedQuery;
  whitelistKeys?: string[];
}

export const getQueriesCount = ({
  blacklistKeys = [],
  groupKeys = [],
  queries,
  whitelistKeys = [],
}: GetQueriesCountData): number => {
  if (!queries) {
    // console.log('getQueriesCount. params do not exist: ', queries);
    return 0;
  }

  let filteredKeys = whitelistKeys;
  if (whitelistKeys.length && blacklistKeys.length) {
    filteredKeys = whitelistKeys.filter((key) => !blacklistKeys.includes(key));
  }

  const queryKeys = Object.getOwnPropertyNames(queries).filter((key) =>
    filteredKeys.length ? filteredKeys.includes(key) : true
  );

  if (!queryKeys.length) {
    // console.log('getQueriesCount. view params are empty: ', Object.keys(queries), 'whitelist: ', whitelistKeys);
    return 0;
  }

  const queryHasLengthyKey = (key: string) => Object.prototype.hasOwnProperty.call(queries, key) && queries[key];
  const flattenedGroupKeys = groupKeys.flat();
  let count = 0;

  // Single keys
  queryKeys
    .filter((key) => !flattenedGroupKeys.includes(key))
    .forEach((key) => {
      // console.log(`getQueriesCount. checking single key "${key}": `, queries[key]);
      if (queryHasLengthyKey(key)) {
        count++;
      }
    });

  // Group keys
  groupKeys.forEach((group) => {
    // console.log(`getQueriesCount. checking group: `, group, queries);
    if (group.some((key) => queryKeys.includes(key) && queryHasLengthyKey(key))) {
      count++;
    }
  });

  return count;
};

export const compressString = (value: string): string => {
  // Compress string to Uint8Array
  const compressedUint8Array = deflate(value);

  // Convert Uint8Array to Base64-encoded string
  return btoa(String.fromCharCode.apply(null, Array.from(compressedUint8Array)));
};

export const decompressString = (compressedValue: string): string => {
  // Convert Base64-encoded string to Uint8Array
  const compressedUint8Array = new Uint8Array(
    atob(compressedValue)
      .split('')
      .map((char) => char.charCodeAt(0))
  );

  // Decode Uint8Array
  const decompressedUint8Array = inflate(compressedUint8Array);

  // Convert Uint8Array to string
  return new TextDecoder().decode(decompressedUint8Array);
};

export const generateFeatureId = () => {
  return Math.random().toString(36).replace('0.', '');
};

export const removeEmptyQueries = (query: any) => pickBy(query, identity);

export const reduceNumbersArray = (data: IGraphItem[]) => data.reduce((acc, el) => acc + el.value, 0);

export const getInitials = (fullName: string) =>
  fullName
    .split(' ')
    .splice(0, 2)
    .map((n) => n[0]?.toUpperCase())
    .join('')
    .replace(/ +(?= )/g, '');

export const valueToYesOrNo = (value?: any) => {
  if (value && Number(value) == 1) {
    return t('yes');
  }

  if (value == null) {
    return '-';
  }

  return t('no');
};

export const getBuildingTypeState = (buildingTypes: string | any[], constants: ConfigConstants) => {
  const buildingTypesMap = constants?.buildingTypeGroups;
  const state = {
    hasCommercialLandType: false,
    hasIndustrialType: false,
    hasOfficeType: false,
    hasOtherType: false,
    hasRetailType: false,
    values: [],
  };

  if (!buildingTypesMap) {
    return state;
  }

  // Wrap single building type in an array
  if (typeof buildingTypes === 'string' && buildingTypes) {
    buildingTypes = [buildingTypes];
  }

  if (Array.isArray(buildingTypes)) {
    buildingTypes.forEach(function (item) {
      const buildingTypeValue = typeof item === 'object' ? item.value : item;

      if (
        !state.hasCommercialLandType &&
        buildingTypesMap[BuildingTypeGroups.commercialLand].keys.indexOf(buildingTypeValue) !== -1
      ) {
        state.hasCommercialLandType = true;
      }
      if (
        !state.hasIndustrialType &&
        buildingTypesMap[BuildingTypeGroups.industrial].keys.indexOf(buildingTypeValue) !== -1
      ) {
        state.hasIndustrialType = true;
      }
      if (!state.hasOfficeType && buildingTypesMap[BuildingTypeGroups.office].keys.indexOf(buildingTypeValue) !== -1) {
        state.hasOfficeType = true;
      }
      if (!state.hasOtherType && buildingTypesMap[BuildingTypeGroups.other].keys.indexOf(buildingTypeValue) !== -1) {
        state.hasOtherType = true;
      }
      if (!state.hasRetailType && buildingTypesMap[BuildingTypeGroups.retail].keys.indexOf(buildingTypeValue) !== -1) {
        state.hasRetailType = true;
      }

      // @ts-ignore
      state.values.push(buildingTypeValue);
    });
  }

  // console.log('buildingTypes: ', buildingTypes);
  // console.log('building types state: ', state);
  return state;
};

export const getSocietiesState = (user: IUser) => {
  // Default society filters
  // let societyFilters = typeof user?.society_filters === 'object' ? user.society_filters : {};

  // Society data
  let primaryGroup: PrimarySocietyGroup | null = null;

  let oasSocietyGroups: IUserSociety['groups'] = [];
  const societyGroupIds: number[] = [];
  const societyIds: Societies[] = [];
  const societyIdsGroupHash: { [key: number]: Societies } = {};
  let isWEASMember = false;
  let isCACMember = false;
  let isOASMember = false;
  let isIASMember = false;
  let isSASMember = false;

  for (const society of user.societies) {
    // Populate the society lists
    societyIds.push(society.id);

    // Add to the list of society group ids
    for (const group of society.groups) {
      societyGroupIds.push(group.id);
      societyIdsGroupHash[group.id] = society.id;

      // Store the first primary group id
      if (!primaryGroup && group.primary) {
        primaryGroup = {
          id: group.id,
          society: society.id,
          filterByGroup: society.id === Societies.OAS,
        };
      }
    }

    // Set flags
    if (society.id === Societies.WEAS) {
      isWEASMember = true;
    } else if (society.id === Societies.CAC) {
      isCACMember = true;
    } else if (society.id === Societies.OAS) {
      isOASMember = true;
      oasSocietyGroups = society.groups;
    } else if (society.id === Societies.IAS) {
      isIASMember = true;
    } else if (society.id === Societies.SAS) {
      isSASMember = true;
    }
  }

  return {
    isCACMember,
    isCACOnly: isCACMember && user?.societies.length === 1,
    isIASAndAnotherMember: isIASMember && (isWEASMember || isCACMember || isOASMember),
    isIASMember,
    isIASOnly: isIASMember && user?.societies.length === 1,
    isOASMember,
    isOASOnly: isOASMember && user?.societies.length === 1,
    isSASMember,
    isSASOnly: isSASMember && user?.societies.length === 1,
    isWEASMember,
    isWEASOnly: isWEASMember && user?.societies.length === 1,
    multipleSocieties: (user?.societies.length || 0) > 1,
    oasSocietyGroups,
    primaryGroup,
    societyGroupIds,
    societyIdsGroupHash,
    societyIds,
  };
};

export const serialize = (data: any) => {
  return data ? JSON.stringify(data) : '';
};

export const deserialize = (str = '', defaultValue: any = {}) => {
  if (!str) {
    return defaultValue;
  }

  try {
    return JSON.parse(str);
  } catch {
    return defaultValue;
  }
};

export const getDataFromStorage = (storageKey: StorageKey): { [key: string]: any } | any => {
  return deserialize(localStorage.getItem(String(storageKey)) || '', '');
};

export const getStringFromStorage = (storageKey: StorageKey): string => {
  return (localStorage.getItem(String(storageKey)) as string) || '';
};

export const setDataToStorage = (storageKey: StorageKey, data?: any) => {
  return localStorage.setItem(String(storageKey), serialize(data));
};

export const setStringIntoStorage = (storageKey: StorageKey, value?: string) => {
  return localStorage.setItem(String(storageKey), value || '');
};

export const deleteStorage = (storageKey: StorageKey) => {
  localStorage.removeItem(String(storageKey));
};

export const getElementOuterHeight = (node: Element | null) => {
  if (!node) {
    return 0;
  }

  const styles = window.getComputedStyle(node);
  const rect = node.getBoundingClientRect();
  return parseInt(styles.marginTop) + rect.height + parseInt(styles.marginBottom);
};

export const filterCsv = (csv: string, whitelist: string[]) => {
  if (!whitelist.length || !csv) {
    return csv;
  }

  return csv
    .split(',')
    .filter((key) => key && whitelist.includes(key))
    .join(',');
};

export const forStringRecord = <TRecordValue = any, TRecordKey extends string = string>(
  record: Record<TRecordKey, TRecordValue>,
  callback: (value: TRecordValue, key: TRecordKey) => void
): void => {
  (Object.getOwnPropertyNames(record) as TRecordKey[]).forEach((key: TRecordKey) => callback(record[key], key));
};

export const forNumberRecord = <TRecordValue = any, TRecordKey extends number = number>(
  record: Record<TRecordKey, TRecordValue>,
  callback: (value: TRecordValue, key: TRecordKey) => void
): void => {
  (Object.getOwnPropertyNames(record).map(Number) as TRecordKey[]).forEach((key: TRecordKey) =>
    callback(record[key], key)
  );
};

export const mapStringRecord = <TRecordValue = any, TRecordKey extends string = string>(
  record: Record<TRecordKey, TRecordValue>,
  callback: (value: TRecordValue, key: TRecordKey) => any
): any[] => {
  return (Object.getOwnPropertyNames(record) as TRecordKey[]).map((key: TRecordKey) => callback(record[key], key));
};

export const mapNumberRecord = <TRecordValue = any, TRecordKey extends number = number>(
  record: Record<TRecordKey, TRecordValue>,
  callback: (value: TRecordValue, key: TRecordKey) => any
): any[] => {
  return (Object.getOwnPropertyNames(record).map(Number) as TRecordKey[]).map((key: TRecordKey) =>
    callback(record[key], key)
  );
};

export const reduceStringRecordToObject = <TRecordValue = any, TNewValue = any, TRecordKey extends string = string>(
  record: Record<TRecordKey, TRecordValue>,
  callback: (result: TNewValue, value: TRecordValue, key: TRecordKey) => TNewValue
): TNewValue =>
  (Object.getOwnPropertyNames(record) as TRecordKey[]).reduce<TNewValue>(
    (result, key) => callback(result, record[key], key),
    {} as TNewValue
  );

export const reduceNumberRecordToObject = <TRecordValue = any, TNewValue = any, TRecordKey extends number = number>(
  record: Record<TRecordKey, TRecordValue>,
  callback: (result: TNewValue, value: TRecordValue, key: TRecordKey) => TNewValue
): TNewValue =>
  (Object.getOwnPropertyNames(record).map(Number) as TRecordKey[]).reduce<TNewValue>(
    (result, key) => callback(result, record[key], key),
    {} as TNewValue
  );

export const reduceStringRecordToArray = <TRecordValue = any, TNewValue = any, TRecordKey extends string = string>(
  record: Record<TRecordKey, TRecordValue>,
  callback: (result: TNewValue[], value: TRecordValue, key: TRecordKey) => TNewValue[]
): TNewValue[] =>
  (Object.getOwnPropertyNames(record) as TRecordKey[]).reduce<TNewValue[]>(
    (result, key) => callback(result, record[key], key),
    [] as TNewValue[]
  );

export const reduceNumberRecordToArray = <TRecordValue = any, TNewValue = any, TRecordKey extends number = number>(
  record: Record<TRecordKey, TRecordValue>,
  callback: (result: TNewValue[], value: TRecordValue, key: TRecordKey) => TNewValue[]
): TNewValue[] =>
  (Object.getOwnPropertyNames(record).map(Number) as TRecordKey[]).reduce<TNewValue[]>(
    (result, key) => callback(result, record[key], key),
    [] as TNewValue[]
  );

export const someStringRecord = <TRecordValue = any, TRecordKey extends string = string>(
  record: Record<TRecordKey, TRecordValue>,
  callback: (value: TRecordValue, key: TRecordKey) => boolean
): boolean =>
  (Object.getOwnPropertyNames(record) as TRecordKey[]).some((key: TRecordKey) => callback(record[key], key));

export const someNumberRecord = <TRecordValue = any, TRecordKey extends number = number>(
  record: Record<TRecordKey, TRecordValue>,
  callback: (value: TRecordValue, key: TRecordKey) => boolean
): boolean =>
  (Object.getOwnPropertyNames(record).map(Number) as TRecordKey[]).some((key: TRecordKey) =>
    callback(record[key], key)
  );

export const getBuildingTypesString = (buildingTypes: string[] = [], buildingTypeOptions: Option[]) => {
  let text = '';
  let delim = '';

  buildingTypes.forEach((type: string) => {
    buildingTypeOptions.forEach((option) => {
      if (type === option.id) {
        text += delim + option.label;
        delim = ', ';
      }
    });
  });
  return text;
};
