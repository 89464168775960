import { FilterPeriodState, FilterPeriodType } from '@shared/components/filter-period';
import { GroupedOption } from '@shared/components/group-select';
import { Option } from '@shared/components/select-new/Select';
import { SwitchType } from '@shared/components/switch';

import { FilterAutocompleteItemState } from './autocomplete';
import { Range } from './range';

export enum FilterType {
  date,
  dateRange,
  lookupCompany,
  lookupContact,
  lookupUser,
  multiGroupSelect,
  multiSelect,
  range,
  singleGroupSelect,
  singleSelect,
  switch,
  text,
}

export type FiltersTypeConfig<TFT = FilterType> = {
  type: TFT;
  label: string;
  labelCheckbox?: boolean;
  removable?: boolean;
  placeholder?: string;
  visible?: boolean;
};

interface DateFilterTypeConfig extends FiltersTypeConfig<FilterType.date> {
  supportedTypes?: FilterPeriodType[];
}

interface MultiSelectFilterTypeConfig extends FiltersTypeConfig<FilterType.multiSelect> {
  options: Option[];
}

interface MultiGroupSelectFilterTypeConfig extends FiltersTypeConfig<FilterType.multiGroupSelect> {
  groupOptions: GroupedOption[];
}

interface RangeFilterTypeConfig extends FiltersTypeConfig<FilterType.range> {
  defaultMetric?: Metric;
}

interface SingleGroupSelectFilterTypeConfig extends FiltersTypeConfig<FilterType.singleGroupSelect> {
  groupOptions: GroupedOption[];
}

interface RangeFilterTypeConfig extends FiltersTypeConfig<FilterType.range> {
  metric?: boolean;
  metricOptions?: Option[];
}

interface SingleSelectFilterTypeConfig extends FiltersTypeConfig<FilterType.singleSelect> {
  options: Option[];
}

interface SwitchFilterTypeConfig extends FiltersTypeConfig<FilterType.switch> {
  switchType: SwitchType;
  condition?: boolean;
}

export type FiltersTypeConfigs =
  | DateFilterTypeConfig
  | FiltersTypeConfig<FilterType.dateRange>
  | FiltersTypeConfig<FilterType.lookupCompany>
  | FiltersTypeConfig<FilterType.lookupContact>
  | FiltersTypeConfig<FilterType.lookupUser>
  | FiltersTypeConfig<FilterType.text>
  | MultiGroupSelectFilterTypeConfig
  | MultiSelectFilterTypeConfig
  | RangeFilterTypeConfig
  | SingleGroupSelectFilterTypeConfig
  | SingleSelectFilterTypeConfig
  | SwitchFilterTypeConfig;

export enum AvailableFilters {
  buildingTypes,
  capVal,
  company,
  companyType,
  completionDate,
  contact,
  contactType,
  created,
  createdCrmCompanies,
  createdCrmContacts,
  createdDateRange,
  createdTransactionsSales,
  disposalDevelopmentCompletionYear,
  disposalDevelopmentFloorSize,
  disposalDevelopmentFunding,
  disposalDevelopmentNoOfFloors,
  disposalDevelopmentPreLetCommitted,
  disposalDevelopmentSpeculative,
  disposalDevelopmentStatus,
  disposalDevelopmentTotalAreaProposed,
  disposalDevelopmentType,
  disposalFittedSpaces,
  disposalHasPresentation,
  disposalPublishedTo,
  disposalStatus,
  disposalTermsOfEngagement,
  fitoutConcept,
  fitoutCondition,
  has_active_disposals,
  has_active_leases,
  has_active_requirements,
  has_active_sales,
  has_active_viewings,
  investment,
  investmentGrade,
  jointExclusiveInstructions,
  kycCompleted,
  leaseBreak,
  leaseEnd,
  leaseExpiry,
  leaseReminder,
  leaseStart,
  leaseTypes,
  location,
  marketing_lists,
  marketing_permission,
  newMatches,
  niy,
  ourInstructions,
  pendingMatches,
  postcode,
  publishedToRadius,
  rent,
  rentReview,
  reverseSegmentIntersectLogic,
  salePrice,
  saleTypes,
  sectors,
  signatureDate,
  size,
  sources,
  tags,
  teams,
  tenancyStatus,
  tenure,
  tny,
  transactionStatus,
  updated,
  user,
  waultToExpiry,
}

export type AvailableFiltersHash = { [key: number]: AvailableFilters };

export interface FilterRangeState extends Range {
  metric?: Metric;
}

interface FilterStateValue<T = any> {
  checkboxValue?: boolean;
  value: T;
}

export type FilterStateValues = {
  [FilterType.date]: FilterStateValue<FilterPeriodState>;
  [FilterType.dateRange]: FilterStateValue<Range>;
  [FilterType.lookupCompany]: FilterStateValue<FilterAutocompleteItemState>;
  [FilterType.lookupContact]: FilterStateValue<FilterAutocompleteItemState>;
  [FilterType.lookupUser]: FilterStateValue<FilterAutocompleteItemState>;
  [FilterType.multiGroupSelect]: FilterStateValue<Id[]>;
  [FilterType.multiSelect]: FilterStateValue<Id[]>;
  [FilterType.range]: FilterStateValue<FilterRangeState>;
  [FilterType.singleGroupSelect]: FilterStateValue<Id | undefined>;
  [FilterType.singleSelect]: FilterStateValue<Id | undefined>;
  [FilterType.switch]: FilterStateValue<boolean | undefined>;
  [FilterType.text]: FilterStateValue<string | undefined>;
};

export type AllFilterStateValues = ValueOf<FilterStateValues>;

type PartialFilterStateValue<T extends AllFilterStateValues = AllFilterStateValues> = FilterStateValue<
  Partial<T['value']>
>;

export type PartialFilterStateValues = {
  [FilterType.date]: PartialFilterStateValue<FilterStateValues[FilterType.date]>;
  [FilterType.dateRange]: PartialFilterStateValue<FilterStateValues[FilterType.dateRange]>;
  [FilterType.lookupCompany]: PartialFilterStateValue<FilterStateValues[FilterType.lookupCompany]>;
  [FilterType.lookupContact]: PartialFilterStateValue<FilterStateValues[FilterType.lookupContact]>;
  [FilterType.lookupUser]: PartialFilterStateValue<FilterStateValues[FilterType.lookupUser]>;
  [FilterType.multiGroupSelect]: FilterStateValue<FilterStateValues[FilterType.multiGroupSelect]['value']>;
  [FilterType.multiSelect]: FilterStateValue<FilterStateValues[FilterType.multiSelect]['value']>;
  [FilterType.range]: PartialFilterStateValue<FilterStateValues[FilterType.range]>;
  [FilterType.singleGroupSelect]: FilterStateValue<FilterStateValues[FilterType.singleGroupSelect]['value']>;
  [FilterType.singleSelect]: PartialFilterStateValue<FilterStateValues[FilterType.singleSelect]>;
  [FilterType.switch]: PartialFilterStateValue<FilterStateValues[FilterType.switch]>;
  [FilterType.text]: PartialFilterStateValue<FilterStateValues[FilterType.text]>;
};
