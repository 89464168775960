import { t } from '@core/i18n';

export const normalizedHTMLText = (text?: string) => {
  if (!text) return '';

  return text.replace(/\n/g, '<br />');
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getMetricString = (metric: Metric) => {
  switch (metric) {
    case 'sqft':
      return t('sq_ft');

    case 'sqm':
    case 'sqm_month':
      return t('sq_m');

    case 'acre':
    case 'acres':
      return t('acres');

    case 'desk':
    case 'desks':
      return t('desks');

    case 'hectare':
    case 'hectares':
      return t('hectares');

    case 'annum':
      return t('annum');

    case 'month':
      return t('month');

    case 'bed':
    case 'beds':
      return t('beds');

    default:
      return metric ?? '';
  }
};

export const formatValueMetric = (value: string | number, metric: Metric) => {
  if (!value) {
    return '';
  }

  return value.toString() + ' / ' + getMetricString(metric);
};

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
