import moment, { unitOfTime } from 'moment';

import { t } from '@core/i18n';
import { MaterialDate } from '@shared/components/date-picker';

export const PICKER_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_PICKER_FORMAT = 'DD/MM/YYYY HH:mm a';

export const formatDateFrom = (
  date: moment.MomentInput,
  formatFrom: string = PICKER_DATE_FORMAT,
  formatTo: string = DEFAULT_DATE_FORMAT,
  utc = true
) => {
  if (!date) {
    return '';
  }

  const momentDate = utc ? moment.utc(date, formatFrom) : moment(date, formatFrom);
  return momentDate.isValid() ? momentDate.format(formatTo) : '';
};

export const formatDate = (date: moment.MomentInput, format = 'Do MMM YYYY', utc = true) => {
  if (!date) {
    return '';
  }

  const momentDate = utc ? moment.utc(date) : moment(date);
  return momentDate.isValid() ? momentDate.format(format) : '';
};

export const formatTimeFromNow = (date: moment.MomentInput, utc = true) => {
  if (!date) {
    return '';
  }

  const momentDate = utc ? moment.utc(date) : moment(date);
  return momentDate.isValid() ? momentDate.fromNow() : '';
};

export const timeToDate = (time: string, utc = true, type?: 'AM' | 'PM') => {
  const init = utc ? moment.utc : moment;
  const args = {
    time: type ? `${time} ${type}` : time,
    format: type ? ['hh:mm A'] : ['HH:mm'],
  };

  return init(args.time, args.format).toISOString();
};

export const dateToTime = (date: string, utc = true, type?: 'AM' | 'PM') => {
  const format = type ? 'hh:mm' : 'HH:mm';

  return formatDate(date, format, utc);
};

export const isSameDay = (from?: string, to?: string): boolean => {
  if (!from || !to) {
    return false;
  }

  return formatDate(from) === formatDate(to);
};

export const isSameDate = (
  compareToDate: moment.MomentInput,
  comparableDate: moment.MomentInput,
  compareType?: unitOfTime.Base,
  utc?: boolean
) => {
  return utc
    ? moment.utc(comparableDate).isSame(compareToDate, compareType)
    : moment(comparableDate).isSame(compareToDate, compareType);
};

export const isAfter = (
  compareToDate: string,
  comparableDate: string,
  compareType?: unitOfTime.StartOf,
  utc?: boolean
) => {
  const init = utc ? moment.utc : moment;

  return init(comparableDate).isAfter(compareToDate, compareType);
};

export const getTimeDifferenceText = (dateFrom: string, dateTo: string) => {
  const from = moment(dateFrom);
  const to = moment(dateTo).add(1, 'days');

  const count = (label: string, count: number | string) => {
    const numberCount = Number(count);

    if (numberCount == 1) {
      return `${count} ${label} `;
    } else if (numberCount > 1) {
      return `${count} ${label}s  `;
    } else {
      return '';
    }
  };

  let years: number | string = to.diff(from, 'year');
  from.add(years, 'years');

  let months: number | string = to.diff(from, 'months');
  from.add(months, 'months');

  let days: number | string = to.diff(from, 'days');

  years = years > 0 ? count('year', years) : '';
  months = months > 0 ? count('month', months) : '';
  days = days > 0 ? count('day', days) : '';

  return years + months + days;
};

export const getQueryDate = (monthAgo?: number) => {
  if (monthAgo) {
    return moment().subtract(monthAgo, 'months').add(1, 'days').format('YYYY-MM-DD');
  }

  return moment().format('YYYY-MM-DD');
};

export const getViewingDateTimeInfo = (from?: string, to?: string, allDay?: number): string => {
  if (allDay === 1) {
    return t('all_day');
  }
  if (from && !to) {
    return formatDate(from, 'LT');
  }
  if (from && to) {
    return `${formatDate(from, 'LT')} - ${formatDate(to, 'LT')}`;
  }
  return '';
};

function getFirstDayOfQuarterMonth() {
  return moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD');
}

export const getDaysCountSinceQuarterStart = () => {
  const today = moment().format('YYYY-MM-DD');

  return moment(today.split('-')).diff(getFirstDayOfQuarterMonth().split('-'), 'days');
};

export const isEndTimeBeforeStartTime = (date: MaterialDate, startTime: string, endTime: string) => {
  return !moment(date)
    .add(moment.duration(startTime))
    .isBefore(moment(date).add(moment.duration(endTime)));
};

export interface UseCurrentDateResult {
  date: number;
  month: number;
  year: number;
}

export const getCurrentDate = (): UseCurrentDateResult => {
  const now = new Date();

  return {
    date: now.getDate(),
    month: now.getMonth() + 1,
    year: now.getFullYear(),
  };
};

export const getPrettyDate = (date: string) => {
  let prettyDate = '';
  const updated_timestamp = date;
  let prettyDateTooltip = '';
  if (typeof updated_timestamp !== 'undefined') {
    const isToday = moment(updated_timestamp).isSame(moment(), 'day');

    prettyDate = isToday ? moment(updated_timestamp).format('HH:mma') : moment(updated_timestamp).format('DD MMMM');

    prettyDateTooltip = !isToday ? moment(updated_timestamp).format('LLLL') : '';
  }

  return {
    date: prettyDate,
    tooltip: prettyDateTooltip,
  };
};
